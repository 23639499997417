@use '/src/common/Styles/colors';
@use '/src/common/Styles/font';

.sponsor_header {
  font-size: font.$fontsize-BodyLarge;
  font-family: font.$font-family_body;
  line-height: 30px;
  color: colors.$colors-PrimaryDarkest;
  display: flex;
  flex-direction: column;

  .header_label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .chevron {
    font-size: xx-large;
    color: colors.$colors-GreyscaleMediumDark;
  }

  .divider {
    margin: 10px 0 0 0;
  }
}

svg {
  &.flagged {
    color: colors.$colors-SecondaryDefault;
    position: absolute;
    left: -50%;
  }
}

.edit_button {
  align-self: flex-end;
  color: colors.$colors-PrimaryDefault;
  font-size: font.$fontWeight-SemiBold;

  .edit_icon {
    color: colors.$colors-GreyscaleMedium;
  }
}

.incomplete {
  background-color: rgba(255,219,85,0.1);
}

p {
  &.error_message {
    color: colors.$colors-SecondaryDefault;
    text-align: right; 
    font-size: font.$fontsize-BodySmall;
    width: 85%;
  }
}

svg {
  &.error_message {
    color: colors.$colors-SecondaryDefault;
  }
}

.unflagged {
  display: none;
}