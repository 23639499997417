@use '/src/common/Styles/colors';
@use '/src/common/Styles/font';

.overview_section {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  max-width: 926px;

  .overview_section_title {
    color: colors.$colors-GreyscaleDark;
    font-family: font.$font-family_head;
    font-size: font.$fontsize-Header3;
    line-height: 30px;
    margin-bottom: 24px;
  }
}