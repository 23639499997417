@use '/src/common/Styles/colors';
@use '/src/common/Styles/font';

.content_frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 13vw 0 3vw;

  .frame_header {
    height: 105px;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;

    .frame_title {
      display: flex;
      align-content: space-between;
      font-family: font.$font-family_head;
      font-size: 28px;
      line-height: 21px;
      color: colors.$Greyscale-Dark;
    }
  }

  .powerbi_embedded_content_container {
    
    .embedded_content_target {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 115.1vh;

      iframe {
        border: none;
      }
    }
  }

  .refresh_readout {
    font-size: font.$fontsize-BodySmall;
    margin: 0 5px 0 20px;
  }
}