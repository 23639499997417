@use '/src/common/Styles/colors';

.blade_menu {
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: 242px;
  margin-top: 7vh;

  .icon {
    color: colors.$colors-GreyscaleMediumDark;
    margin-right: 12px;
  }
}