$colors-GreyscaleDark: #536163;
$colors-GreyscaleDarkest: #000000;
$colors-GreyscaleLight: #DADADA;
$colors-GreyscaleLightest: #FFFFFF;
$colors-GreyscaleMedium: #999D9F;
$colors-GreyscaleMediumDark: #808587;
$colors-GreyscaleMediumLight: #C0C0C0;
$colors-GreyscaleSuperLight: #F5F5F5;
$colors-PrimaryDark: #14768F;
$colors-PrimaryDarkest: #005268;
$colors-PrimaryDefault: #00A4B3;
$colors-PrimaryLight: #E3FEFF;
$colors-PrimaryMedium: #00929F;
$colors-PrimaryMediumLight: #A9E2E8;
$colors-IconLimeGreen: #4EF374;
$colors-IconBlueGreen: #005E76;
$colors-IconGreen: #00C67E;
$colors-IconPaleBlue: #15CCF4;
$colors-SecondaryDefault: #F56600;
$colors-StatusError: #E2433D;
$colors-StatusNeutral: #42C3EE;
$colors-StatusOk: #19B978;
$colors-StatusWarn: #FED560;
$colors-Tertiary001: #6BEB77;
$colors-Tertiary002: #FC8835;

$gwy-BlueSuperDark: #07243b;

$Primary-Default: #0EB1BD;
$Primary-Medium-Light: #AAE8EC;
$Primary-Medium: #009AAB;
$Primary-Darkest: #005E76;
$Green: #19B978;
$Orange: #FF6700;
$Yellow: #FED560;
$Red: #F2393F;
$Blue: #42C3EE;
$Grey-Dark: #505858;
$Greyscale-Light: #abb7ba;
$Greyscale-Medium: #A3A7A9;
$Greyscale-MedDark: #8A9092;
$Greyscale-Dark: #536163;



