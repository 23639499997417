.app {
  height: 100vh;
  display: flex;
  flex-direction: column;

  div, header {
    box-sizing: border-box;
  }
}

.app_header {
  display: flex;
}

.app_body {
  display: flex;
  padding-top: 60px;
}