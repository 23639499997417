@use '/src/common/Styles/colors';
@use '/src/common/Styles/font';

.empty_deck {
  display: flex;
  justify-content: center;
  padding: 5em;
}

.favorites_deck {

  .card {
    margin: 0 16px 16px 0;
    cursor: pointer;
    border-color: colors.$colors-GreyscaleDarkest;
    box-shadow:
      0 2px 2px 0 rgba(0,0,0,0.14),
      0 3px 1px -2px rgba(0,0,0,0.12),
      0 1px 5px 0 rgba(0,0,0,0.2);

    .card_divider {
      margin: 0 0 5px;
      border-color: colors.$colors-GreyscaleMediumLight;
    }

    .card_avatar {
      background-color: colors.$Blue;
      padding-top: 4px;
    }

    .card_label_container {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-start;
    }

    .card_label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      
      p {
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    
      }
    }

    
    .card_content_name {
      color: colors.$colors-GreyscaleDark;
      font-family: font.$font-family_body;
      font-weight: font.$fontWeight-SemiBold;
      font-size: font.$fontsize-BodyMedium;
      letter-spacing: 0;
      line-height: 24px;
    }

    .card_description {
      font-size: font.$fontsize-BodySmall;
      line-height: 24px;
      color: colors.$colors-GreyscaleMediumDark;
      font-family: font.$font-family_body;
    }

  }
}

.star_badge {
  color: colors.$colors-StatusWarn;
}

.report_icon {
  color: colors.$colors-GreyscaleLightest;
}

.patient_activity_avatar {
  background-color: colors.$colors-IconLimeGreen;
}

.user_mgmt_avatar {
  background-color: colors.$colors-IconBlueGreen;
}

.inventory_avatar {
  background-color: colors.$colors-IconGreen;
}

.site_statuses_avatar {
  background-color: colors.$colors-IconPaleBlue;
}

.tooltip {
  background-color: colors.$colors-GreyscaleDark;
}

.card_content_height{
  height: 140px;
}

.card_display_name {
  margin-top: -23px !important;
}
