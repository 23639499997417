@use '/src/common/Styles/colors';
@use '/src/common/Styles/font';

.drawer {
  .offset_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.blade {
  display: flex;
  cursor: pointer;
  height: 5vh;
  align-items: center;
  padding: 12px 0 12px 12px;
  color: colors.$colors-GreyscaleDark;
  font-family: font.$font-family_head;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 21px;

  &.blade_option {
    padding-left: 48px;
  }
  
  &.selected_blade {
    font-weight: font.$fontWeight-SemiBold;
    background-color: colors.$colors-PrimaryLight;
    border-radius: 0 25px 25px 0;
  }
  
  .arrow {
    font-size: xx-large;
  }

  .icon {
    color: colors.$colors-GreyscaleMediumDark;
    margin-right: 12px;
  }

  input {
    margin: 8px;
  }
}
