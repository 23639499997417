@use '/src/common/Styles/colors';
@use '/src/common/Styles/font';

.app_menu {
  display: flex;
  justify-content: space-between;
  height: 60px;
  z-index: 10;
  width: 100%;
  background: colors.$gwy-BlueSuperDark;
  padding-left: 40px;
  padding-right: 25px;
  position: fixed;
  
  .last_login_container {
    p {
      font-size: 14px;
      margin: 0px 30px;
      font-family: "Open Sans", "Helvetica Neue", "Arial", "Helvetica", "sans-serif";
      color: colors.$Greyscale-Light;
    }
  }
}

.logo {
  margin-top: 11px;
  margin-right: 50px;
  width: 128px;
  height: 38px;
}

.tool_bar {
  display: flex;
}

.app_selection {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  margin: 0;
  
  li {
    display: flex;
    margin-right: 45px;
    line-height: 60px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    a {
      width: 100%;
      height: 100%;
      font-family: font.$font-family_legacy; 
      font-size: 14px;
      font-weight: font.$fontWeight-SemiBold;
      color: colors.$Greyscale-Light;
      transition: all .2s ease;
      text-decoration: none;

      &:hover {
        color: colors.$colors-GreyscaleLightest;
      }
    }

    .app_menu_highlight {
      color: colors.$colors-GreyscaleLightest;
    }

    .analytics_underline {
      display: block;
      height: 6px;
      margin: -6px -15px 0;
      border-top-left-radius: 3px;
      border-top: 3px solid #00aebd;
      border-top-right-radius: 3px;
      border-bottom: 3px solid #00aebd;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.avatar_halo {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  &:hover, &:active {
    border : 2px solid colors.$colors-PrimaryDefault;
    border-radius: 50%;
  }
}

.user_menu_item {
  a {
    height: 40px;
    line-height: 40px;
    color: colors.$colors-PrimaryDefault;
    transition: all .2s ease;
    white-space: nowrap;
    font-family: font.$font-family_legacy;
    text-transform: unset;
  }
}

.help_icon {
  cursor: pointer;
  margin-right: 28px;
  padding-bottom: 4px;
  color: colors.$Greyscale-Light;

  &:hover, &:active {
    color: colors.$colors-GreyscaleLightest;
  }
}