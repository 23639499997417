@use '/src/common/Styles/colors';

.fave_star {
  margin-right: 10px;

  &.favorite {
    color: colors.$colors-StatusWarn;
  }

  &.non_favorite {
    color: colors.$colors-GreyscaleLight;
  }
}

.once {
  pointer-events: none;
}