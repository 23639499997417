@use '/src/common/Styles/colors';
@use '/src/common/Styles/font'; 

.frequency_table_container {
  height: 385px;
  margin-bottom: 32px;

  .frequency_table_header {
    background-color: colors.$colors-GreyscaleSuperLight;

    th {
      border-right: 1px solid colors.$colors-GreyscaleMedium;
      border-bottom: unset;
    }
  }
}